<template>
  <div id="app">
    <router-view/>

    <div class="browser-tip" v-if="$store.state.app.chromTip">
      <p class="browser-tip-nr">为了您能得到我们网站最好的体验效果，避免出现兼容性问题，我们建议您使用“<img src="./assets/images/new_icon/google.png" />Chrome”浏览器</p>
      <img src="./assets/images/new_icon/close.png" @click="closeTip" />
    </div>
    <div class="notice-interact">
      <div class="notice-interact-nums" :class="interactStatus ? 'is-fold' : 'not-fold'">
        <div class="notice-interact-nums-l">
          <img src="./assets/images/new_icon/tubiao.png" width="25" height="30" />
          <p>你有<span>{{interactTaskCount}}</span>个{{noticeName}}</p>
        </div>

        <div @click="changeDetailStatus" class="notice-interact-nums-btn" >{{showInteract ? '关闭详情' : '查看详情'}}</div>
      </div>
      <div class="notice-interact-list">
        <el-collapse-transition>
          <div v-show="showInteract">
            <div class="transition-box notice-interact-list-item" v-for="item in interactList" :key="item.id">
              <div class="notice-interact-list-item-l">
                <p>{{ item.interact.name }}</p>
                <p>试题数量: {{item.interact.data_count}}  题</p>
              </div>
              <div class="notice-interact-list-item-btn" @click="goTrain(item)">去答题</div>
            </div>
            <div class="transition-box notice-interact-list-item" v-for="item in trainList" :key="item.id">
              <div class="notice-interact-list-item-l">
                <p>{{ item.name }}</p>
                <p>试题数量: {{item.data_count}}  题</p>
              </div>
              <div class="notice-interact-list-item-btn" @click="goExam(item)">去考试</div>
            </div>
            <div class="transition-box notice-interact-list-item" v-for="item in examList" :key="item.id">
              <div class="notice-interact-list-item-l">
                <p>{{ item.name }}</p>
                <p>试题数量: {{item.data_count}}  题</p>
              </div>
              <div class="notice-interact-list-item-btn" @click="goCourseExam(item)" >去考试</div>
            </div>
          </div>
        </el-collapse-transition>
      </div>
    </div>

  </div>
</template>
<script>
import Cookies from "js-cookie";
import {tokenName} from '@/router/init.js'
import util from '@/utils/tools.js'
export default {
  name:'app',
  data(){
    return{
      isChrome:true,
      tipStatus:false,
      showInteract: false,
      interactTaskCount:0,
      infoReady:false,
      interactStatus:false,
      interactList:[],
      trainList:[],
      examList:[],
      courseInteractTaskCount:0,
      examineCount:0,
      heartbeatTimer:null,
    }
  },
  computed:{
    noticeName(){
      // console.log(this.courseInteractTaskCount,this.examineCount,'this.examineCount')
      if(this.courseInteractTaskCount > 0 && this.examineCount == 0){
        return '待完成互动答题'
      }else if(this.courseInteractTaskCount == 0 && this.examineCount > 0){
        return '待完成考试'
      }else{
        return '待办事项'
      }
    }
  },
  watch:{
    '$store.state.app.loginStatus':{
      handler(newV){
        if(newV && this.$store.state.user.userInfo){
          this.infoReady = true;
        }else{
          this.infoReady = false;
        }
      },
      immediate:true
    },
    '$store.state.user.userInfo':{
      handler(newV){
        // console.log(newV,this.$store.state.user.userInfo,'newVnewV')
        // console.log(newV,this.$store.state.app.loginStatus,'this.$store.state.loginStatus')
        if(newV,this.$store.state.app.loginStatus){
          this.infoReady = true;
        }else{
          this.infoReady = false;
        }
      },
      immediate:true
    },
    infoReady:{
      handler(newV){
        if(newV){ //学生且为需要登录的页面
          //连接websocket
          // this.init();
        }else{
          // if(this.socket){
          //   this.socket.close();  //关闭socket
          //   this.interactStatus = false;    //隐藏活动内容
          //   this.showInteract = false;
          // }
        }
      },
      immediate:true,
    },
    '$store.state.app.loginStatus':{
      handler(newV){
        if(newV){ //登录
          console.log(newV,'+++++')
          this.init();
        }else{
          this.$socket.close();
          clearInterval(this.heartbeatTimer);
        }
      },
      immediate:true,
    }
  },
  created(){
    this.isChrome =  navigator.userAgent.indexOf('Chrome') > -1;
    if(!this.isChrome){
      this.$store.commit('changeChromTip',true)
    }


    // if(this.$route.requiresAuth.requiresAuth){
    //
    // }
    // var isFirefox =  navigator.userAgent.indexOf('Firefox') > -1
  },
  methods:{
    closeTip(){
      this.$store.commit('changeChromTip',false)
    },
    init: function () {
      if(typeof(WebSocket) === "undefined"){
        alert("您的浏览器不支持socket")
      }else{
        let token = Cookies.get(this.constant.tokenName);
        // 实例化socket
        this.$socket = new WebSocket(`${this.constant.WSS}?token=${token}`)
        // 监听socket连接
        this.$socket.onopen = this.open
        // 监听socket错误信息
        this.$socket.onerror = this.error
        // 监听socket消息
        this.$socket.onmessage = this.getMessage
      }
    },
    open: function () {
      console.log("socket连接成功")
      clearInterval(this.heartbeatTimer);
      this.heartbeatTimer=setInterval(()=>{
        this.$socket.send(JSON.stringify({data:"0",message:"0"}));
      },5000);
    },
    error: function (err) {
      console.log(err,"socket连接错误")
    },
    getMessage: function (msg) {
      let data = JSON.parse(msg.data).data;

      if(data.action =='heartbeat'){
        this.courseInteractTaskCount = data.user_stat.course_interact_task_count;
        this.examineCount = data.user_stat.examine_count;
        if(data.user_stat.course_interact_task_count > 0 || data.user_stat.examine_count > 0){
          this.interactStatus = true;
        }else{
          this.interactStatus = false;
        }
        if(this.interactTaskCount != (Number(data.user_stat.course_interact_task_count) + Number(data.user_stat.examine_count)) && this.showInteract){
          this.getDetail();
        }
        this.interactTaskCount = Number(data.user_stat.course_interact_task_count) + Number(data.user_stat.examine_count);
      }
    },
    // 发送消息给被连接的服务端
    send: function (params) {
      this.socket.send(params)
    },
    close: function () {
      console.log("socket已经关闭")
    },
    changeDetailStatus(){
      this.showInteract = !this.showInteract;
      this.getDetail();
    },
    getDetail(){

      if(this.showInteract){
        let params = {
          status:0
        };
        this.api.course.doingWaitExamList(params).then((res)=>{
          this.interactList = res.interact_task_list;
          this.examList = res.examine_list;
          this.trainList = res.train_list;
        })
      }else{
        this.interactList = [];
      }
    },
    goTrain(data){
      if(data.train.generate_site_id != this.$store.state.user.userInfo.site.id){
        this.$Message.warning(`该练习不属于当前组织，请切换至${data.site.name}再参与此练习`)
        return;
      }
      this.$router.push({
        path:'/course/interactExam',
        query:{
          id:data.train_id,
        }
      })
    },
    goExam(data){
      if(data.generate_site_id != this.$store.state.user.userInfo.site.id){
        this.$Message.warning(`该考试不属于当前组织，请切换至${data.site.name}再参与此考试`)
        return;
      }
      if(data.my_join_status == 1){
        this.startExam(data)
      }else{
        this.goPreExam(data)
      }
      // this.goPreExam(data)
    },
    startExam(data){
      this.$router.push({
        path:'/exam',
        query:{
          id:data.id,
        }
      })
    },
    goPreExam(data){
      this.$router.push({
        path:'/trainingcamp/pre-exam',
        query:{
          id:data.id,
        }
      })
    },
    goCourseExam(data){
      if(data.generate_site_id != this.$store.state.user.userInfo.site.id){
        this.$Message.warning(`该考试不属于当前组织，请切换至${data.site.name}再参与此考试`)
        return;
      }
      if(data.my_join_status == 1){
        this.startCourseExam(data)
      }else{
        this.goCoursePreExam(data)
      }
      // this.goCoursePreExam(data)
    },
    startCourseExam(data){
      if(data.train && data.train.id){
        this.$router.push({
          path:'/exam',
          query:{
            id:data.train.id,
          }
        })
      }else{
        this.$router.push({
          path:'/exam',
          query:{
            examineId:data.id,
          }
        })
      }
    },
    goCoursePreExam(data){
      this.$router.push({
        path:'/trainingcamp/pre-exam',
        query:{
          id:data.id,
          from:'courseWeek'
        }
      })
    },
  },
  beforeDestory(){
    this.$socket.close();
    clearInterval(this.heartbeatTimer);
  }
}
</script>
<style lang="scss">
#app {
  //font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //text-align: center;
  //color: #2c3e50;
  height: 100%;
  background-color: #ECEDF2;
  overflow-y: auto;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
body,html{
  height: 100%;
}
.app-bj-color{
  background: #F6F7FA !important;
  color: #333333 !important;
}
.text-color{
  color: #4A79FF;
}
.border-left-yellow{
  border-left: 3px solid #FFCA6A;
}
.border-left-green{
  border-left: 3px solid #36CBCB;
}
.border-left-red{
  border-left: 3px solid #F2637B;
}
.app-fgx{
  width: 1px;
  height: 100%;
  background: #DDDDDD;
}
.pl10{
  padding-left: 10px;
}
.browser-tip{
  padding: 0 60px;
  position: fixed;
  left: 0;
  bottom: 0;
  height: 66px;
  background: #4A79FF;
  opacity: 0.95;
  border-radius: 1px;
  z-index: 99;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .browser-tip-nr{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    >img{
      padding: 0 5px;
    }
  }
}
.notice-interact{
  position: absolute;
  right: 20px;
  top: 56px;
  z-index: 99;


  .notice-interact-nums{

    width: 0;
    //transition: width 200s;
    transition: width 0.2s ease; /* 添加宽度过渡动画 */
    height: 44px;
    background: #FFFFFF;
    box-shadow: 0px 0px 24px 3px rgba(31,71,105,0.16);
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .notice-interact-nums-l{
      margin-left: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      >p{
        margin-left: 4px;
        >span{
          color:#4A79FF;
        }
      }
    }
    .notice-interact-nums-btn{
      margin-right: 10px;
      width: 70px;
      height: 30px;
      background: #4A79FF;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  .is-fold {
    width: 312px;
  }

  .not-fold {
    width: 0;
    overflow: hidden;
    //border: 1px solid #f2f3f8;
  }

  .notice-interact-list{

    max-height: 600px;
    overflow-y: auto;
    .notice-interact-list-item{
      margin-top: 10px;
      padding: 12px 10px;
      width: 312px;
      min-height: 64px;
      background: #F6F7FA;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .notice-interact-list-item-l{
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        flex: 1;
        width: 0;
        margin-right: 6px;
        >p:nth-child(1){
          font-size: 14px;
          font-weight: bold;
          color: #333333;
        }
      }
      .notice-interact-list-item-btn{
        width: 70px;
        height: 30px;
        background: #4A79FF;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }
}
.ivu-btn-primary{
  background-color: #4A79FF !important;
  border-color: #4A79FF !important;
}
.ivu-checkbox-checked .ivu-checkbox-inner{
  border-color:#4A79FF !important;
  background-color:#4A79FF !important;
}
.ivu-btn-error{
  border-color:#ED1919 !important;
  background-color: #ED1919 !important;
}
.el-radio__input.is-checked+.el-radio__label{
  color: #4A79FF !important;
}
.el-radio__input.is-checked .el-radio__inner{
  border-color:#4A79FF !important;
  background-color:#4A79FF !important;
}
.ivu-input-search:hover{
  border-color:#4A79FF !important;
  background-color:#4A79FF !important;
  background: #4A79FF !important;
}
.ivu-input-search{
  border-color:#4A79FF !important;
  background-color:#4A79FF !important;
  background: #4A79FF !important;
}
.top-tip{
  padding-left: 14px;
  height: 40px;
  background: #F8F8F9;
  border: 1px dashed #CCCCCC;
  border-radius: 4px;
  font-size: 14px;
  color: #333333;
  line-height: 40px;
}

.el-button--primary{
  border-color:#4A79FF !important;
  background-color:#4A79FF !important;
  background: #4A79FF !important;
}
.el-checkbox__input.is-checked+.el-checkbox__label{
  color: #4A79FF !important;
}
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
  background-color: #4A79FF !important;
  border-color: #4A79FF !important;
}
.el-drawer {
  .el-drawer__header {
    margin: 0 20px;
    padding: 20px 0 0 0;
    border-bottom: 1px solid #E8EAEC;
  }

  .drawer-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;


    .drawer-title-text {
      font-size: 18px;
      color: #333333;
      font-weight: bold;
    }

    .drawer-title-btns {
      padding-right: 40px;
    }
  }
}
.el-dialog{
  .dialog-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dialog-title-text {
      font-size: 18px;
      color: #333333;
      font-weight: bold;
    }

    .dialog-title-btns {
      padding-right: 40px;
    }
  }
  .el-dialog__body{
    padding-top: 10px;
  }
}
.el-tag + .el-tag {
  margin-left: 10px;

}
.el-tag{
  color: #4A79FF !important;
}
.el-tag .el-tag__close{
  color: #4A79FF !important;
}

</style>
